<template>
    <div class="form-group row-section">
        <label class="section-label text-uppercase">Password</label>

        <div v-if="!isMobile">
            <el-row>
                <el-col class="small">
                    To change your account password, enter your Current Password, New Password,
                    and Confirm the New Password, then click the “Save” button.
                </el-col>
            </el-row>

            <el-row :gutter="40" class="m-top-3">
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label" for="profile-current-password">Current Password</label>
                        <input
                                :class="{'has-error': (errorsForm.currPasswordInvalid)}"
                                class="form-control"
                                id="profile-current-password"
                                placeholder="Enter current password"
                                type="password"
                                v-model="credentials.current_password"
                        />

                        <label class="text-danger small" v-if="errorsForm.currPasswordInvalid">
                            <i class="el-icon-warning"></i>
                            {{ errorsForm.curr_password }}
                        </label>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="40" class="m-top-3">
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label" for="profile-new-password">New Password</label>
                        <input
                                :class="{'has-error': (errorsForm.passwordInvalid)}"
                                @input="validation"
                                class="form-control"
                                id="profile-new-password"
                                placeholder="Enter new password"
                                type="password"
                                v-model="credentials.password"
                        />

                        <label class="text-danger small" v-if="errorsForm.passwordInvalid">
                            <i class="el-icon-warning"></i>
                            {{ errorsForm.password }}
                        </label>
                    </div>
                </el-col>
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label
                                class="control-label"
                                for="profile-password-confirmation"
                        >Confirm New Password</label>
                        <input
                                :class="{'has-error': (errorsForm.passwordConfirmInvalid)}"
                                class="form-control"
                                id="profile-password-confirmation"
                                placeholder="Enter new password"
                                type="password"
                                v-model="credentials.password_confirmation"
                        />

                        <label class="text-danger small" v-if="errorsForm.passwordConfirmInvalid">
                            <i class="el-icon-warning"></i>
                            {{ errorsForm.password_confirmation }}
                        </label>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="40" class="m-top-3">
                <el-col :span="24" :xs="24">Your password must meet these requirements:</el-col>
                <el-col :span="12" :xs="24" class="m-top-3">
                    <password-checker :errors-form="errorsForm"></password-checker>
                </el-col>
            </el-row>
        </div>
        <div v-else>
            <el-row>
                <el-col class="small" id="profile-password-desc-mobile">
                    <div class="p-lr-25px-mobile">
                        To change your account password, enter your Current Password, New Password,
                        and Confirm the New Password, then click the “Save” button.
                    </div>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12" :xs="24">
                    <div class="form-group p-lr-25px-mobile">
                        <label class="control-label" for="profile-current-password">Current Password</label>
                        <input
                                :class="{'has-error': (errorsForm.currPasswordInvalid)}"
                                class="form-control"
                                id="profile-current-password"
                                placeholder="Enter current password"
                                type="password"
                                v-model="credentials.current_password"
                        />

                        <label class="text-danger small" v-if="errorsForm.currPasswordInvalid">
                            <i class="el-icon-warning"></i>
                            {{ errorsForm.curr_password }}
                        </label>
                    </div>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12" :xs="24">
                    <div class="form-group p-lr-25px-mobile">
                        <label class="control-label" for="profile-new-password">New Password</label>
                        <input
                                :class="{'has-error': (errorsForm.passwordInvalid)}"
                                @input="validation"
                                class="form-control"
                                id="profile-new-password"
                                placeholder="Enter new password"
                                type="password"
                                v-model="credentials.password"
                        />

                        <label class="text-danger small" v-if="errorsForm.passwordInvalid">
                            <i class="el-icon-warning"></i>
                            {{ errorsForm.password }}
                        </label>
                    </div>
                </el-col>
                <el-col :span="12" :xs="24">
                    <div class="form-group p-lr-25px-mobile">
                        <label
                                class="control-label"
                                for="profile-password-confirmation"
                        >Confirm New Password</label>
                        <input
                                :class="{'has-error': (errorsForm.passwordConfirmInvalid)}"
                                class="form-control"
                                id="profile-password-confirmation"
                                placeholder="Enter new password"
                                type="password"
                                v-model="credentials.password_confirmation"
                        />

                        <label class="text-danger small" v-if="errorsForm.passwordConfirmInvalid">
                            <i class="el-icon-warning"></i>
                            {{ errorsForm.password_confirmation }}
                        </label>
                    </div>
                </el-col>
            </el-row>

            <el-row id="profile-password-bottom-mobile" class="p-lr-25px-mobile">
                <el-col :span="24" :xs="24">Your password must meet these requirements:</el-col>
                <el-col :span="12" :xs="24" class="m-top-3">
                    <password-checker :errors-form="errorsForm"></password-checker>
                </el-col>
            </el-row>
        </div>

    </div>
</template>

<script>
    import PasswordChecker from "./PasswordChecker";

    export default {
        name: "ProfilePassword",
        components: {PasswordChecker},
        props: {
            errorsForm: Object,
            credentials: Object
        },
        data: () => ({
            dataChanged: false,
            isMobile: false,
        }),
        beforeDestroy() {
            window.vEvent.stop("password-validation", this.validation);
        },
        mounted() {
            window.vEvent.listen("password-validation", this.validation);
        },
        created() {
            this.mobileCheck();
        },
        methods: {
            validation() {
                this.dataChanged = true;
                this.errorsForm.passwordType = {
                    uppercase: false,
                    lowercase: false,
                    number: false,
                    special: false,
                    minimum: false
                };

                let upper = /^(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                if (upper.test(String(this.credentials.password))) {
                    this.errorsForm.passwordType.uppercase = true;
                }

                let lower = /^(?=.*[a-z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                if (lower.test(String(this.credentials.password))) {
                    this.errorsForm.passwordType.lowercase = true;
                }

                let number = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                if (number.test(String(this.credentials.password))) {
                    this.errorsForm.passwordType.number = true;
                }

                let special = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                if (special.test(String(this.credentials.password))) {
                    this.errorsForm.passwordType.special = true;
                }

                let minimum = /^(?=.{8,})[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
                if (minimum.test(String(this.credentials.password))) {
                    this.errorsForm.passwordType.minimum = true;
                }
            },
            mobileCheck() {
                let BC_onMobile = false;
                if (window.screen.availWidth <= 414 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    BC_onMobile = true;
                }

                this.isMobile = BC_onMobile;
            }
        }
    };
</script>
