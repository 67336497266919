<template>
    <div
        class="account-information settings-right-area"
        id="account-information"
    >
        <right-header-footer v-if="!onAdminPage" class="header-items m-top-3" :class='isMobile ? "d-flex justify-content-between" : ""'>
            <template v-slot:title>Account Information</template>
            <template v-slot:actions>
                <div class="form-controls" :class='isMobile ? "d-flex flexEnd" : ""'>
                    <MobileMenu :isMobile='isMobile'></MobileMenu>
                    <button
                        @click="cancelProcess"
                        class="btn btn-light m-left-2 btn-rounded"
                        type="button"
                        v-if="
                            changed.changed_profile === true ||
                                credentials.password.length ||
                                credentials.password_confirmation.length
                        "
                    >
                        Cancel
                    </button>
                    <button
                        @click="saveProfileInfo"
                        class="btn btn-primary m-left-2"
                        type="button"
                    >
                        <span v-if='!loading'>
                            <i class="far fa-save" v-if="!isMobile"></i>
                            Save
                        </span>
                        <span v-else>
                            <base-loader :showLoader='loading' />
                        </span>
                    </button>
                </div>
            </template>
        </right-header-footer>

        <el-container>
            <el-card v-if="user" class="no-shadow">
                <div class="header-title">Account Information</div>

                <profile-picture v-if="!onAdminPage"
                    :user="user"
                    :changed="changed"
                ></profile-picture>

                <profile-picture-for-admin v-else :user="userInfo"></profile-picture-for-admin>


                <profile-information
                    :errors-form="errorsForm"
                    :user="user"
                    :changed="changed"
                ></profile-information>

                <contact-address
                    :errors-form="errorsForm"
                    :user="user"
                    :changed="changed"
                ></contact-address>

                <div class="form-group row-section">
                    <label class="section-label text-uppercase"
                        >Account Timezone</label
                    >
                    <el-row>
                        <el-col class="small">
                            <div class="p-lr-25px-mobile">
                                All account activities and analytics will be
                                reported using the timezone selected below.
                                Also, features that require time selection will
                                use this timezone.
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="40" class="m-top-3">
                        <el-col :span="12" :xs="24">
                            <div class="form-group m-bottom-1 p-lr-25px-mobile">
                                <label class="control-label">Timezone</label>
                                <el-select
                                    :value="user.settings.timezone"
                                    class="w-100 custom_select"
                                    popper-class="custom_select_pop admin_select_pop"
                                    filterable
                                    placeholder="Select Timezone"
                                    v-model="user.settings.timezone"
                                    @change="onChangeTimeZone"
                                    :disabled="onAdminPage"
                                >
                                    <el-option
                                        :key="`timezone_${index}`"
                                        :label="timezone['name']"
                                        :value="timezone['code']"
                                        v-for="(timezone, index) in $store.state
                                            .timezones"
                                    ></el-option>
                                </el-select>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <profile-password
                    v-if="!onAdminPage"
                    :credentials="credentials"
                    :errors-form="errorsForm"
                    :user="user"
                ></profile-password>
            </el-card>
        </el-container>
    </div>
</template>

<script>
import RightHeaderFooter from './Layout/RightHeaderFooter';
import ProfilePicture from './Items/ProfilePicture';
import ProfileInformation from './Items/ProfileInformation';
import ContactAddress from './Items/ContactAddress';
import ProfilePassword from './Items/ProfilePassword';
import ProfilePictureForAdmin from '../Admin/UsersTab/UserInfo/ProfilePictureForAdmin';
import MobileMenu from './Layout/MobileMenu';

export default {
    name: 'AccountInformation',
    components: {
        ProfilePassword,
        ContactAddress,
        ProfileInformation,
        ProfilePicture,
        RightHeaderFooter,
        ProfilePictureForAdmin,
        MobileMenu
    },
    props: {
        userInfo: Object
    },
    computed: {
        errorsForm: {
            get() {
                return this.$store.state.errorsForm;
            }
        },
        onAdminPage: function() {
            return this.$route.path.includes('admin');
        }
    },
    data: () => ({
        credentials: {
            current_password: '',
            password: '',
            password_confirmation: ''
        },
        user: false,
        changed: {
            changed_profile: {
                type: Boolean,
                default: false
            }
        },
        isMobile: false,
        loading: false,
    }),
    watch: {
        userInfo: function() {
            this.user = JSON.parse(JSON.stringify(this.userInfo));
        },
    },
    beforeDestroy() {
        window.Ls.remove('sub-user-info');
    },
    mounted() {
        if (window.Ls.get('sub-user-info')) {
            this.user = JSON.parse(window.Ls.get('sub-user-info'));
        } else {
            if (this.userInfo) {
                this.user = JSON.parse(JSON.stringify(this.userInfo));
            } else {
                let vm = this;
                let userInterval = setInterval(function() {
                    if (vm.userInfo) {
                        vm.user = JSON.parse(JSON.stringify(vm.userInfo));
                        clearInterval(userInterval);
                    }
                }, 300);
            }
        }
    },
    created() {
        this.mobileCheck();
    },
    methods: {
        onChangeTimeZone() {
            this.changed.changed_profile = true;
        },

        cancelProcess() {
            this.credentials.current_password = '';
            this.credentials.password = '';
            this.credentials.password_confirmation = '';
            this.validatePassword();
            this.changed.changed_profile = false;
            //this.$router.go();

            window.location.reload();
        },

        saveProfileInfo() {
            this.clearValidate();

            this.changed.changed_profile = false;
            // this.credentials.current_password = '';
            // this.credentials.password = '';
            // this.credentials.password_confirmation = '';
            let valid = true;

            if (
                this.user.first_name === '' ||
                this.user.first_name === null ||
                this.user.first_name === ' '
            ) {
                this.errorsForm.firstNameRequired = true;
                valid = false;
            } else {
                this.errorsForm.firstNameRequired = false;
            }
            if (!this.validateName(this.user.first_name)) {
                this.errorsForm.nameInvalid = true;
                valid = false;
            }

            if (this.user.last_name !== '') {
                if (!this.validateName(this.user.last_name)) {
                    this.errorsForm.nameInvalid = true;
                    valid = false;
                }
            }

            if (this.credentials.password !== '') {
                if (
                    !this.credentials.current_password ||
                    this.credentials.current_password === ''
                ) {
                    this.errorsForm.currPasswordInvalid = true;
                    valid = false;
                }
            }

            if (
                this.credentials.current_password !== '' &&
                this.credentials.password !== ''
            ) {
                if (!this.validatePassword()) {
                    this.errorsForm.passwordInvalid = true;
                    valid = false;
                }

                if (
                    this.credentials.password !==
                    this.credentials.password_confirmation
                ) {
                    this.errorsForm.passwordConfirmInvalid = true;
                    valid = false;
                }
            }

            if (!valid) {
                return false;
            }

            let post_data = {
                data: {
                    id: this.user.id,
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    street_address: this.user.settings.street_address,
                    apartment_suite: this.user.settings.apartment_suite,
                    city: this.user.settings.city,
                    state: this.user.settings.state,
                    country: this.user.settings.country,
                    zip_code: this.user.settings.zip_code,
                    company: this.user.settings.company,
                    phone: this.user.phone,
                    timezone: this.user.settings.timezone,
                    password: this.credentials.password,
                    current_password: this.credentials.current_password
                },
                url: '/settings/update-profile-info'
            };
            this.loading = true;
            this.$store
                .dispatch('post', { ...post_data })
                .then(response => {
                    this.loading = false;
                    if (response.data.result === 'success') {
                        if (!window.Ls.get('sub-user-info')) {
                            this.$store.commit('setUserInfo', this.user);
                        } else {
                            let vm = this;
                            this.$nextTick(function() {
                                this.$store.dispatch('getUserData').then(re => {
                                    vm.$forceUpdate();
                                });
                            });
                        }

                        window.vEvent.fire('settings-common-success', {
                            title: 'Account Information',
                            message: 'Successfully Updated',
                            close: true
                        });
                    } else {
                        this.errorsForm.currPasswordInvalid = true;
                    }
                })
                .catch(error => {
                    this.errorsForm.currPasswordInvalid = true;
                    this.loading = true;
                });
        },

        clearValidate() {
            this.errorsForm.firstNameRequired = false;
            this.errorsForm.nameInvalid = false;
            this.errorsForm.passwordInvalid = false;
            this.errorsForm.currPasswordInvalid = false;
            this.errorsForm.passwordConfirmInvalid = false;
            this.errorsForm.passwordType = {
                uppercase: false,
                lowercase: false,
                number: false,
                special: false,
                minimum: false
            };
        },

        validateName(name) {
            let re = /^[a-zA-Z]+$/;
            return re.test(String(name).toLowerCase());
        },

        validatePassword() {
            window.vEvent.fire(
                'password-validation',
                this.credentials.password
            );

            let re = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
            return re.test(String(this.credentials.password));
        },

        mobileCheck() {
            let BC_onMobile = false;
            if (
                window.screen.availWidth <= 1024 ||
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                BC_onMobile = true;
            }

            this.isMobile = BC_onMobile;
        },
    }
};
</script>

<style lang="less" scoped>
.account-information {
    position: relative;
}
.no-shadow{
    box-shadow: none;
}
@media (max-width: 960px) {
    .el-container,
    .is-always-shadow {
        padding: 0px !important;
    }
}
</style>
