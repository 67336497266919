import moment from "moment";

const filterMixin = {
  methods: {
    formatSize(val, unitNum, levelToRound = 2) {
      let num = +val;
      if (!+num) {
        return 0;
      }
      const units = ["b", "KB", "MB", "GB", "TB"];
      let unit = unitNum;
      while (num / 1024 > 0.9) {
        num = num / 1024;
        if (unit < 4) unit += 1;
      }
      if (num - Math.floor(num) > 0) {
        // console.log(num)
        // console.log(`${num.toFixed(levelToRound)}`)
        return `${num.toFixed(levelToRound)} ${units[unit]}`;
      }
      return `${num.toFixed(0)} ${units[unit]}`;
    },
  },
  filters: {
    formatForm(val) {
      if (!val) {
        return "";
      }
      if (val == 1) {
        return val + " issue";
      }
      return val + " issues";
    },
    formatFigure(val) {
      if (!val) {
        return 0;
      }
      const formatter = new Intl.NumberFormat("en");
      return formatter.format(val);
    },
    countMonths(val) {
      return moment().diff(val, "months");
    },
    countDays(val) {

      return moment(val).diff(moment().startOf('day'), "days");
    },
    formatToDay: function (date) {
      return moment(date).calendar(null, {
        sameDay: "[Today]",
        lastDay: "[Yesterday]",
        lastWeek: "[Last week]",
        lastMonth: `[Last month]`,
        sameElse: `[${moment(date).fromNow()}]`,
      });
    },
    formatTime: function (val) {
      const d = Number(val);
      const hours = Math.floor(d / 3600);
      const min = Math.floor((d % 3600) / 60);
      const seconds = Math.floor((d % 3600) % 60);
      return `${hours} hours ${min} minutes ${seconds} seconds`;
    },
  },
};

const onClickMixin = {
  methods: {
    onClick(id, name) {
      this.$store.commit("addUserAccountName", name);
      this.$store.dispatch("getUserInfo", id);
      this.$router.push(`/admin/users/${id}`);
    },
  },
};

const passwordMixin = {
  methods: {
    passwordValidation(password) {
      window.vEvent.fire("password-validation", password);

      let re = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
      return re.test(String(password));
    },

    passwordGenerate() {
      let chars =
        "abcdefghijklmnopqrstuvwxyz!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
      let pass = "";
      for (let x = 0; x < 6; x++) {
        let i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
      }

      let chars1 = "!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?";
      for (let x1 = 0; x1 < 2; x1++) {
        let i1 = Math.floor(Math.random() * chars1.length);
        pass += chars1.charAt(i1);
      }

      let chars2 = "abcdefghijklmnopqrstuvwxyz";
      for (let x2 = 0; x2 < 2; x2++) {
        let i2 = Math.floor(Math.random() * chars2.length);
        pass += chars2.charAt(i2);
      }

      let chars3 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (let x3 = 0; x3 < 2; x3++) {
        let i3 = Math.floor(Math.random() * chars3.length);
        pass += chars3.charAt(i3);
      }

      let chars4 = "1234567890";
      for (let x4 = 0; x4 < 2; x4++) {
        let i4 = Math.floor(Math.random() * chars4.length);
        pass += chars4.charAt(i4);
      }

      if (this.passwordValidation(pass)) {
        return pass;
      } else {
        this.passwordGenerate();
      }
    },
  },
};

export { filterMixin, onClickMixin, passwordMixin };
