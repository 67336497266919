<template>
    <div class="settings-right-header-footer">
        <div class="header-footer-left-side title" :class="{'link-highlight': subcriptionHighlightClass}">
            <slot name="title"></slot>
        </div>
        <div class="header-footer-left-side title" :class="{'link-highlight': billingHighlightClass}">
            <slot name="second-title"></slot>
        </div>
        <div class="header-footer-right-side">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "RightHeaderFooter",
    data: () => ({
        subcriptionHighlightClass: false,
        billingHighlightClass: false
    }),
    watch: {
        "$route.path": function(newVal) {
            this.setHeaderLinkBold(newVal);
        }
    },
    beforeDestroy() {},
    mounted() {},
    created() {},
    methods: {
        setHeaderLinkBold(path) {
            if (
                path === "/settings/billing" ||
                path === "/settings/billing/change-subscription-plan"
            ) {
                this.subcriptionHighlightClass = true;
                this.billingHighlightClass = false;
            } else if (
                path === "/settings/billing/estimate" ||
                path === "/settings/billing/information" ||
                path.includes("/settings/billing/receipt")
            ) {
                this.billingHighlightClass = true;
                this.subcriptionHighlightClass = false;
            }
        }
    }
};
</script>

<style lang="less" scoped>
@tablet: ~"only screen and (min-width: 720px) and (max-width: 959px)";
@mobile: ~"only screen and (min-width: 300px) and (max-width: 414px)";
.settings-right-header-footer {
    width: 100%;
    display: flex;
    align-items: center;
    @media @mobile {
        display: flex !important;
    }
    &.header-items {
        padding: 0 0 2rem 0;
    }

    &.footer-items {
        padding: 2rem 0;
    }

    .header-footer-left-side {
        text-align: left;
        width: 23%;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 20px;
        color: var(--textThemeColor);
        @media @mobile {
            font-size: 16px;
            width: max-content;
            //                width: fit-content !important;
        }
    }

    .header-footer-right-side {
        text-align: right;
        width: max-content;
        position: absolute;
        right: 5px;
        margin: auto;
        @media @mobile {
            width: 50% !important;
            padding-right: 0px !important;
            margin-right: 0px !important;
            margin-top: 0px !important;
            position: relative !important;
            right: 0px !important;
        }
        button.btn.btn-primary {
            @media @mobile {
                font-size: 13px !important;
                padding: 8px 20px !important;
            }
        }
    }
}

.flexEnd {
    justify-content: flex-end;
}

@media (max-width: 960px) {
    .header-footer-left-side {
        width: max-content !important;
    }
    .header-footer-right-side {
        position: relative !important;
    }
}

</style>
