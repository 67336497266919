<template>
    <div class="form-group row-section">
        <label class="section-label text-uppercase">Profile Information</label>

        <div v-if="!isMobile">
            <el-row :gutter="40">
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label" for="profile-first-name"
                            >First Name</label
                        >
                        <input
                            :class="{
                                'has-error': errorsForm.firstNameRequired,
                                'disabled': onAdminPage
                            }"
                        
                        :disabled="onAdminPage"
                            @input="changeNameField"
                            @keydown="onInfoChanged"
                            class="form-control"
                            id="profile-first-name"
                            placeholder="Enter first name"
                            type="text"
                            v-model="user.first_name"
                        />

                        <label
                            class="text-danger small"
                            v-if="errorsForm.firstNameRequired"
                        >
                            <i class="el-icon-warning"></i>
                            {{ errorsForm.first_name }}
                        </label>
                    </div>
                </el-col>

                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label" for="profile-last-name"
                            >Last Name</label
                        >
                        <input
                            :class="{
                                'has-error':
                                    user.last_name !== '' &&
                                    errorsForm.nameInvalid
                            }"
                            @input="changeNameField"
                            @keydown="onInfoChanged"
                            class="form-control"
                            id="profile-last-name"
                            placeholder="Enter last name"
                            type="text"
                            v-model="user.last_name"
                                                    :disabled="onAdminPage"
                        />
                    </div>
                </el-col>
                <el-col
                    :span="20"
                    :xs="24"
                    class="text-danger small m-bottom-2"
                    v-if="errorsForm.nameInvalid"
                >
                    <i class="el-icon-warning"></i>
                    {{ errorsForm.name }}
                </el-col>
            </el-row>

            <el-row :gutter="40" class="m-top-2">
                <el-col :span="12" :xs="24">
                    <div class="form-group">
                        <label class="control-label" for="profile-email"
                            >Email Address</label
                        >
                        <div
                            class="form-control align-items-center div-form-control"
                            :class="onAdminPage? 'disabled' : ''"
                            id="profile-email"
                        >
                            <div class="w-100 font-weight-normal">
                                {{ user.email }}
                            </div>
                            <div class="text-right" id="profile-email-btn" :class="onAdminPage? '' : 'settings-page'">
                                <a @click="showEmailDialog = true">Change</a>
                            </div>
                        </div>
                    </div>
                    <div class="text-success" v-if='showEmailVerifyMessage'>
                        Email change request sent to {{ user.email }}, please verify.
                    </div>
                </el-col>

                <el-col class="admin-row-dates" :span="12" :xs="24">
                    <div class="form-group row-section">
                        <el-row :gutter="20" class="m-top-3 admin-login">
                            <el-col :span="8" :xs="24" class="opacity-label"
                                >Last Login:</el-col
                            >
                            <!--NEMESIS CHANGE <el-col :span="9" :xs="24" class="opacity-label">Last Login:</el-col> -->
                            <el-col :span="15" :xs="24">{{
                                user.last_login 
                            }}</el-col>
                        </el-row>
                        <el-row :gutter="20" class="m-top-2 admin-member">
                            <el-col :span="8" :xs="24" class="opacity-label"
                                >Member Since:</el-col
                            >
                            <!-- NEMESIS CHANGE <el-col :span="9" :xs="24" class="opacity-label">Member Since:</el-col> -->
                            <el-col :span="15" :xs="24">{{
                                user.member_since
                            }}</el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div v-else>
            <el-row id="profile-information-mobile">
                <el-col :span="12" :xs="24">
                    <div class="form-group">
                        <label class="control-label" for="profile-first-name"
                            >First Name</label
                        >
                        <input
                            :class="{
                                'has-error': errorsForm.firstNameRequired
                            }"
                            @input="changeNameField"
                            @keydown="onInfoChanged"
                            class="form-control"
                            id="profile-first-name"
                            placeholder="Enter first name"
                            type="text"
                            v-model="user.first_name"
                        />

                        <label
                            class="text-danger small"
                            v-if="errorsForm.firstNameRequired"
                        >
                            <i class="el-icon-warning"></i>
                            {{ errorsForm.first_name }}
                        </label>
                    </div>
                </el-col>

                <el-col :span="12" :xs="24">
                    <div
                        class="form-group"
                        id="profile-information-last-name-mobile"
                    >
                        <label class="control-label" for="profile-last-name"
                            >Last Name</label
                        >
                        <input
                            :class="{
                                'has-error':
                                    user.last_name !== '' &&
                                    errorsForm.nameInvalid
                            }"
                            @input="changeNameField"
                            @keydown="onInfoChanged"
                            class="form-control"
                            id="profile-last-name"
                            placeholder="Enter last name"
                            type="text"
                            v-model="user.last_name"
                        />
                    </div>
                </el-col>
                <el-col
                    :span="20"
                    :xs="24"
                    class="text-danger small m-bottom-2"
                    v-if="errorsForm.nameInvalid"
                >
                    <i class="el-icon-warning"></i>
                    {{ errorsForm.name }}
                </el-col>
            </el-row>

            <el-row class="m-top-2">
                <el-col :span="12" :xs="24">
                    <div
                        class="form-group"
                        id="profile-information-email-mobile"
                    >
                        <label class="control-label" for="profile-email"
                            >Email Address</label
                        >
                        <div
                            class="form-control align-items-center div-form-control"
                            id="profile-email"
                        >
                            <div class="w-100 font-weight-normal">
                                {{ user.email }}
                            </div>
                            <div class="text-right" id="profile-email-btn">
                                <a @click="showEmailDialog = true">Change</a>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-dialog
            :append-to-body="true"
            :center="true"
            :visible.sync="showEmailDialog"
            title="Change Email"
            top="30vh"
            width="414px"
        >
            <auth-email-field
                :credentials="credentials"
                :errors-form="errorsForm"
            ></auth-email-field>
            <div class="dialog-footer" slot="footer">
                <el-button @click="showEmailDialog = false" round type="light"
                    :loading='loading'>Cancel</el-button
                >
                <el-button @click="changeEmail" round type="primary"
                    :loading='loading'>Change</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import AuthEmailField from '../../Auth/Items/AuthEmailField';

export default {
    name: 'ProfileInformation',
    components: { AuthEmailField },
    props: {
        user: Object,
        errorsForm: Object,
        changed: Object
    },
    data: () => ({
        showEmailDialog: false,
        credentials: {
            email: ''
        },
        isMobile: false,
        showEmailVerifyMessage: false,
        loading: false,
    }),
    computed: {
        onAdminPage: function() {
            return this.$route.path.includes('admin');
        }
    },
    mounted() {
        this.credentials.email = this.user.email;
    },
    created() {
        this.mobileCheck();
    },
    methods: {
        changeNameField() {
            this.user.full_name = `${this.user.first_name} ${this.user.last_name}`;
        },

        onInfoChanged() {
            this.changed.changed_profile = true;
        },

        changeEmail() {
            this.errorsForm.emailInvalid = false;
            this.errorsForm.emailExist = false;
            this.errorsForm.bemailEmpty = false;
            this.changed.changed_profile = true;

            if (this.credentials.email === '') {
                this.errorsForm.bemailEmpty = true;
                return false;
            } else {
                this.errorsForm.bemailEmpty = false;
            }

            if (this.credentials.email === this.user.email) {
                this.showEmailDialog = false;

                return false;
            }

            if (this.validateEmail()) {
                let post_data = {
                    id: this.user.id,
                    data: {...this.credentials, id: this.user.id},
                    url: '/settings/change-account-email'
                };
                this.loading = true;
                this.$store
                    .dispatch('post', { ...post_data })
                    .then(response => {
                        this.loading = false;
                        if (response.data.result === 'success') {
                            this.user.email = response.data.email;
                            this.user.billing_status =
                                response.data.billing_status;
                            this.showEmailDialog = false;
                            this.showEmailVerifyMessage = true;
                        } else {
                            this.errorsForm.emailExist = true;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        if (error.response.status === 422) {
                            if (error.response.data.errors) {
                                if (error.response.data.errors.email) {
                                    this.errorsForm.emailExist = true;
                                }
                            }
                        }
                    });
            } else {
                this.errorsForm.emailInvalid = true;
            }
        },

        /**
         * Email validation check.
         * @returns {boolean}
         */
        validateEmail() {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(this.credentials.email).toLowerCase());
        },

        mobileCheck() {
            let BC_onMobile = false;
            if (
                window.screen.availWidth <= 414 ||
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                BC_onMobile = true;
            }

            this.isMobile = BC_onMobile;
        }
    }
};
</script>

<style lang="less" scoped>
#profile-email.div-form-control {
    position: relative;

    #profile-email-btn {
        display: none;
    }

    &:hover {
        #profile-email-btn.settings-page {
            display: block;
        }
    }
}
</style>
