<template>
    <div class="form-group row-section">
        <label class="section-label text-uppercase">Profile Picture</label>

        <el-row class="el-row-profile-picture" v-if="!isMobile">
            <el-col :span="3" :xs="10"> <!--NEMESIS CHANGE <el-col :span="4" :xs="10"> -->
                <div @click="toggleShow" class="avatar-img">
                    <img
                            :src="user.photo_url"
                            alt="Profile Avatar"
                            class="img-circle img-responsive"
                            width="86px"
                            height="86px"
                    />
                </div>
            </el-col>

            <el-col :span="20" :xs="24">
                <label class="control-label" style="font-size: 16px">Add Your Picture</label>
                <div class="small w-100">For best results, upload a high resolution image.</div>

                <div class="m-top-1">
                    <button
                            @click="toggleShow"
                            class="btn btn-default btn-primary-outline"
                            type="button">
                        {{ addedProfilePicture ? 'Replace' : 'Add' }} Picture
                    </button>

                    <el-dropdown
                            @command="dropdownSelect"
                            class="m-left-2"
                            trigger="click"
                            v-show="addedProfilePicture"
                            placement="bottom">
                        <div class="btn btn-default btn-primary-outline btn-circle el-dropdown-link">
                            <i class="el-icon-more"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown" class="custom_dropdown custom_dropdown dropdown_bottom_160">
                            <el-dropdown-item
                                    command="deleteAvatar"
                                    icon="el-icon-delete">
                                Remove Picture
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <profile-upload
                        :headers="headers"
                        :height="300"
                        :no-rotate="false"
                        :params="params"
                        :url="upload_url"
                        :width="300"
                        @crop-success="cropSuccess"
                        @crop-upload-fail="cropUploadFail"
                        @crop-upload-success="cropUploadSuccess"
                        field="avatar"
                        img-format="jpg"
                        lang-type="en"
                        ref="avatar_uploader"
                        v-model="showPictureModal"
                ></profile-upload>
            </el-col>
        </el-row>
        <div v-else>
            <div class="profile-picture-mobile">
                <div @click="toggleShow" class="avatar-img">
                    <img
                            :src="user.photo_url"
                            alt="Profile Avatar"
                            class="img-circle img-responsive"
                    />
                </div>
                <div id="profile-picture-desc-mobile">
                    <label class="control-label" style="font-size: 16px">Add Your Picture</label>
                    <div class="small w-100">For best results, upload a high resolution image.</div>
                </div>
            </div>
            <div class="m-top-1" id="profile-picture-btns-mobile">
                <button
                        @click="toggleShow"
                        class="btn btn-default btn-primary-outline"
                        type="button">
                    {{ addedProfilePicture ? 'Replace' : 'Add' }} Picture
                </button>

                <el-dropdown
                        @command="dropdownSelect"
                        class="m-left-2"
                        trigger="click"
                        v-show="addedProfilePicture"
                        placement="bottom">
                    <div class="btn btn-default btn-primary-outline btn-circle el-dropdown-link">
                        <i class="el-icon-more"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown" class="custom_dropdown custom_dropdown dropdown_bottom_160">
                        <el-dropdown-item
                                command="deleteAvatar"
                                icon="el-icon-delete">
                            Remove Picture
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <profile-upload
                    :headers="headers"
                    :height="300"
                    :no-rotate="false"
                    :params="params"
                    :url="upload_url"
                    :width="300"
                    @crop-success="cropSuccess"
                    @crop-upload-fail="cropUploadFail"
                    @crop-upload-success="cropUploadSuccess"
                    field="avatar"
                    img-format="jpg"
                    lang-type="en"
                    ref="avatar_uploader"
                    v-model="showPictureModal"
            ></profile-upload>
        </div>
    </div>
</template>

<script>
    import profileUpload from "vue-image-crop-upload";
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "ProfilePicture",
        components: {profileUpload},
        props: {
            user: Object,
            changed: Object
        },
        data: () => ({
            showPictureModal: false,
            addedProfilePicture: false,
            upload_url: '',
            headers: {
                Authorization: ""
            },
            params: {
                id: 0
            },
            isMobile: false
        }),
        computed: {
            ...mapGetters({
                isLoggedIn: "isAuthenticated"
            })
        },
        mounted() {
            this.addedProfilePicture = !(
                this.user.photo_url.indexOf("www.gravatar.com") > -1
            );
            // this.upload_url = `${process.env.VUE_APP_BACKEND_URL}settings/avatar-upload`;
            this.upload_url = `${process.env.VUE_APP_BACKEND_URL}api/settings/avatar-upload`;
            this.headers.Authorization = `Bearer ${this.$store.state.accessToken}`;
        },
        created() {
            this.mobileCheck();
        },
        methods: {
            ...mapActions({
                removeProfileAvatar: "removeProfileAvatar"
            }),

            ...mapMutations({
                destroyAccessToken: "destroyAccessToken"
            }),

            /**
             * Toggle upload modal
             */
            toggleShow() {
                this.showPictureModal = !this.showPictureModal;
            },

            /**
             * Crop Image Success Event
             * @param imgDataUrl
             * @param field
             */
            cropSuccess(imgDataUrl, field) {
                this.user.photo_url = imgDataUrl;
                this.user.settings.user_image = imgDataUrl;
                this.user.settings.avatar = imgDataUrl;
                this.params.id = this.user.id;
            },

            /**
             * Upload Success Event
             * @param jsonData
             * @param field
             */
            cropUploadSuccess(jsonData, field) {
                if (jsonData.result === "success") {
                    this.showPictureModal = false;
                    this.addedProfilePicture = true;

                    if (!window.Ls.get("sub-user-info")) {
                        window.vEvent.fire("update-user-data", this.user);
                    } else {
                        let vm = this;
                        this.$nextTick(function () {
                            this.$store.dispatch("getUserData").then(re => {
                                vm.$forceUpdate();
                            });
                        });
                    }

                    this.$refs.avatar_uploader.setStep(1);

                    this.changed.changed_profile = true;
                }
            },
            cropUploadFail(status, field) {
                if (status === 401) {

                } else {
                    console.log(status);
                }
            },

            /**
             * Delete Avatar File
             */
            removeAvatarFile() {
                if (this.isLoggedIn) {
                    this.removeProfileAvatar({id: this.user.id})
                        .then(r => {
                            if (r.data.result === "success") {
                                this.user.photo_url = r.data.photo_url;
                                this.user.settings.user_image = r.data.photo_url;
                                this.user.settings.avatar = r.data.photo_url;
                                this.addedProfilePicture = false;
                                this.changed.changed_profile = true;
                                if (!window.Ls.get("sub-user-info")) {
                                    window.vEvent.fire(
                                        "update-user-data",
                                        this.user
                                    );
                                } else {
                                    let vm = this;
                                    this.$nextTick(function () {
                                        this.$store
                                            .dispatch("getUserData")
                                            .then(re => {
                                                vm.$forceUpdate();
                                            });
                                    });
                                }
                            }
                        })
                        .catch(e => {
                            this.$message.error(e.response.message);
                        });
                } else {
                    this.destroyAccessToken();
                    this.$router.push("/login/1");
                }
            },

            dropdownSelect(command) {
                this.changed.changed_profile = true;
                if (command === "deleteAvatar") {
                    this.removeAvatarFile();
                }
            },

            mobileCheck() {
                let BC_onMobile = false;
                if (window.screen.availWidth <= 414 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    BC_onMobile = true;
                }

                this.isMobile = BC_onMobile;
            }
        }
    };
</script>

<style lang="less" scoped>
    @tablet: ~"only screen and (min-width: 720px) and (max-width: 959px)";
    @mobile: ~"only screen and (min-width: 300px) and (max-width: 414px)";
    .avatar-img {
        width: 100px;
        height: 100px;
        cursor: pointer;
        position: relative;
        border-radius: 50%;
        @media @mobile {
            width: 64px;
            height: 64px;
        }
        &:before {
            content: "";
            position: absolute;
            background: url(../../../assets/img/photo_camera_icon.svg) center -40px no-repeat;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: none;
        }

        &:hover {
            border: 2px dashed #0dabd8;

            &:before {
                display: block;
            }
        }
    }

    #avatar-photo-upload {
        opacity: 0.01;
        width: 1px;
        height: 1px;
    }

    @media (max-width: 960px) {
        .el-row-profile-picture {
            display: flex;
        }
    }
</style>
