<template>
    <div class="profile-picture-for-admin">
        <div class="profile-picture-heading">profile picture</div>
        <div class="picture">
            <img
                class="avatar-img"
                :src="user.photo_url || noAvatar"
                alt="avatar"
            />
        </div>
        <div class="block">
            <div class="heading">{{ user.full_name }}</div>
            <div class="role">
                <div class="role-text">{{ user.currentTeam.role }}</div>
            </div>
            <div class="last_activity">
                Last activity: {{ user.last_activity | formatToDate }}
            </div>
        </div>
    </div>
</template>
<script>
import { NO_AVATAR, DATE_FORMATS } from '../../../../constants/';
import { filterMixin } from '../../../../constants/mixins';
import moment from 'moment';

export default {
    name: 'ProfilePictureForAdmin',
    props: ['user'],
    data: () => {
        return {
            noAvatar: NO_AVATAR
        };
    },
    filters: {
        formatToDate: function(date) {
            let day = moment(date).format(DATE_FORMATS.lastActivity);
            return day;
        }
    }
};
</script>
<style lang="less" scoped>
.profile-picture-for-admin {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    .profile-picture-heading {
        text-transform: uppercase;
        font-weight: bold;
        flex-basis: 100%;
        font-size: 13px;
        margin: 5px 0;
    }
    .picture {
        margin-right: 30px;
    }
    .avatar-img {
        width: 86px;
        height: 86px;
        border-radius: 43px;
        object-fit: cover;
    }
    .heading {
        font-size: 16px;
        font-weight: 500;
        flex-basis: 50%;
    }

    .role-text {
        flex-basis: 50%;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        background-color: #f6f7f9;
        border-radius: 20px;
        padding: 5px 15px;
        width: fit-content;
    }
    .role {
        margin: 10px 0;
    }
}
</style>
