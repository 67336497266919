<template>
    <div class="form-group row-section">
        <label class="section-label text-uppercase">Contact Address</label>
        <div v-if="!isMobile">
            <el-row :gutter="40" class="m-top-2">
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label
                            class="control-label"
                            for="profile-street-address"
                            >Street Address</label
                        >
                        <input
                            class="form-control"
                            :disabled="onAdminPage"
                            id="profile-street-address"
                            placeholder="Enter street address"
                            type="text"
                            @keydown="onInfoChanged"
                            v-model="user.settings.street_address"
                        />
                    </div>
                </el-col>

                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label
                            class="control-label"
                            for="profile-apartment-suite"
                            >Apartment, Suite</label
                        >
                        <input
                            :disabled="onAdminPage"
                            class="form-control"
                            id="profile-apartment-suite"
                            placeholder="Enter apartment, suite"
                            type="text"
                            @keydown="onInfoChanged"
                            v-model="user.settings.apartment_suite"
                        />
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="40" class="m-top-2">
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label" for="profile-city"
                            >City</label
                        >
                        <input
                            class="form-control"
                            id="profile-city"
                            placeholder="Enter city"
                            type="text"
                            @keydown="onInfoChanged"
                            v-model="user.settings.city"
                            :disabled="onAdminPage"
                        />
                    </div>
                </el-col>

                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label"
                            >State/Province/Region</label
                        >
                        <region-select
                            :country="user.settings.country"
                            :defaultRegion="user.settings.state"
                            class-name="w-100"
                            v-on:input="changedRegion"
                        ></region-select>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="40" class="m-top-2">
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label">Country</label>
                        <country-select
                            :top-country="user.settings.country"
                            class-name="w-100"
                            v-on:input="changedCountry"
                        ></country-select>
                    </div>
                </el-col>

                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label" for="profile-zip-code"
                            >Zip Code</label
                        >
                        <input
                            :disabled="onAdminPage"
                            class="form-control"
                            id="profile-zip-code"
                            placeholder="Enter Zip Code"
                            type="text"
                            @keydown="onInfoChanged"
                            v-model="user.settings.zip_code"
                        />
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="40" class="m-top-2">
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label">Phone Number</label>
                        <vue-tel-input
                            :default-country="user.settings.country"
                            :dynamic-placeholder="true"
                            :input-classes="['form-control']"
                            :preferred-countries="['US']"
                            :valid-characters-only="true"
                            :disabled="onAdminPage"
                            v-model="user.phone"
                            @change="onInfoChanged"
                            v-on:country-changed="changedCountryOfPhone"
                        ></vue-tel-input>
                    </div>
                </el-col>
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label" for="profile-company"
                            >Company</label
                        >
                        <input
                            class="form-control"
                            id="profile-company"
                            placeholder="Business name"
                            type="text"
                            @input="changedCompany"
                            v-model="user.settings.company"
                            :disabled="onAdminPage"
                        />
                    </div>
                </el-col>
            </el-row>
        </div>
        <div v-else>
            <el-row id="contact-addr-mobile">
                <el-col :span="12" :xs="24">
                    <div class="form-group">
                        <label
                            class="control-label"
                            for="profile-street-address"
                            >Street Address</label
                        >
                        <input
                            class="form-control"
                            id="profile-street-address"
                            placeholder="Enter street address"
                            type="text"
                            @keydown="onInfoChanged"
                            v-model="user.settings.street_address"
                        />
                    </div>
                </el-col>

                <el-col :span="12" :xs="24">
                    <div class="form-group">
                        <label
                            class="control-label"
                            for="profile-apartment-suite"
                            >Apartment, Suite</label
                        >
                        <input
                            class="form-control"
                            id="profile-apartment-suite"
                            placeholder="Enter apartment, suite"
                            type="text"
                            @keydown="onInfoChanged"
                            v-model="user.settings.apartment_suite"
                        />
                    </div>
                </el-col>
            </el-row>

            <el-row class="p-lr-25px-mobile">
                <el-col :span="12" :xs="24">
                    <div class="form-group">
                        <label class="control-label" for="profile-city"
                            >City</label
                        >
                        <input
                            class="form-control"
                            id="profile-city"
                            placeholder="Enter city"
                            type="text"
                            @keydown="onInfoChanged"
                            v-model="user.settings.city"
                        />
                    </div>
                </el-col>

                <el-col :span="12" :xs="24">
                    <div class="form-group">
                        <label class="control-label"
                            >State/Province/Region</label
                        >
                        <region-select
                            :country="user.settings.country"
                            :defaultRegion="user.settings.state"
                            class-name="w-100"
                            v-on:input="changedRegion"
                        ></region-select>
                    </div>
                </el-col>
            </el-row>

            <el-row class="p-lr-25px-mobile">
                <el-col :span="12" :xs="24">
                    <div class="form-group">
                        <label class="control-label">Country</label>
                        <country-select
                            :top-country="topCountry"
                            class-name="w-100"
                            v-on:input="changedCountry"
                        ></country-select>
                    </div>
                </el-col>

                <el-col :span="12" :xs="24">
                    <div class="form-group">
                        <label class="control-label" for="profile-zip-code"
                            >Zip Code</label
                        >
                        <input
                            class="form-control"
                            id="profile-zip-code"
                            placeholder="Enter Zip Code"
                            type="text"
                            @keydown="onInfoChanged"
                            v-model="user.settings.zip_code"
                        />
                    </div>
                </el-col>
            </el-row>

            <el-row class="p-lr-25px-mobile">
                <el-col :span="12" :xs="24">
                    <div class="form-group">
                        <label class="control-label">Phone Number</label>
                        <vue-tel-input
                            :default-country="user.settings.country"
                            :dynamic-placeholder="true"
                            :input-classes="['form-control']"
                            :preferred-countries="['US']"
                            :valid-characters-only="true"
                            v-model="user.phone"
                            @change="onInfoChanged"
                            v-on:country-changed="changedCountryOfPhone"
                        ></vue-tel-input>
                    </div>
                </el-col>
                <el-col :span="12" :xs="24">
                    <div class="form-group m-bottom-1">
                        <label class="control-label" for="profile-company"
                            >Company</label
                        >
                        <input
                            class="form-control"
                            id="profile-company"
                            placeholder="Business name"
                            type="text"
                            @input="changedCompany"
                            v-model="user.settings.company"
                        />
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<!-- NEMESIS ADD START -->
<style lang="less">
.ContactAddress {
    .el-input__inner {
        border: 2px;
    }
}

@media (max-width: 1024px) {
    .p-lr-25px-mobile {
        padding: 0 25px;
    }
}
</style>
settings-right-area
<!-- NEMESIS ADD END -->
<script>
import CountrySelect from './CountrySelect';
import RegionSelect from './RegionSelect';
import { VueTelInput } from '../../../utils/vue-tel-input';

export default {
    name: 'ContactAddress',
    components: { VueTelInput, RegionSelect, CountrySelect },
    props: {
        user: Object,
        errorsForm: Object,
        changed: Object
    },
    data: () => ({
        topCountry: '',
        first_phone_changed: false,
        phone_number_change_able: true,
        isMobile: false
    }),
    computed: {
        onAdminPage: function() {
            return this.$route.path.includes('admin');
        }
    },
    created() {
        this.mobileCheck();
    },
    methods: {
        onInfoChanged() {
            this.changed.changed_profile = true;
            this.changed.changed_profile = true;
        },

        changedCountry(country) {

            this.user.settings.country = country;
            this.changed.changed_profile = true;
        },

        changedRegion(region) {
            this.user.settings.state = region;
            this.changed.changed_profile = true;
        },

        changedCountryOfPhone(e) {
            if (!this.first_phone_changed) {
                this.first_phone_changed = true;
            } else {
                this.phone_number_change_able = false;
            }
        },

        changedCompany(event) {
            this.user.settings.company = event.target.value;
            this.changed.changed_profile = true;
        },

        mobileCheck() {
            let BC_onMobile = false;
            if (
                window.screen.availWidth <= 414 ||
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                BC_onMobile = true;
            }

            this.isMobile = BC_onMobile;
            // this.topCountry = this.user.settings.country;
        }
    }
};
</script>
